<template>
    <div class="vue-box">
        <!-- 参数栏 -->
        <div class="header">
            <el-link @click="sa_admin.changeTo('project-list')"> 商务管理</el-link>
            <div class="header-item">/</div>
            <el-link> {{title}} </el-link>
        </div>

        <div style="margin: 20px;">
            <el-row>
                <el-col :span="18">
                    <div class="title">基础信息</div>
                </el-col>
                <el-col :span="6">
                    <el-button type="primary" size="small" icon="el-icon-check" @click="ok('ruleForm')">保存</el-button>
                </el-col>
            </el-row>

        </div>

        <div class="c-panel">
            <el-form size="small" v-if="m" ref="ruleForm" :rules="rules" :model="m" label-width="100px">
                <el-form-item label="项目名称：" prop="name">
                    <el-input v-model="m.name"></el-input>
                </el-form-item>
                <el-form-item label="项目状态：">
                    <input-enum enumName="projectStateEnum" v-model="m.state"></input-enum>
                </el-form-item>

                <el-form-item label="联系人电话：" prop="mobile">
                    <el-input v-model="m.mobile"></el-input> 
                    <span style="color:red; font-size:12px"> **小程序登录使用</span>
                </el-form-item>
                <el-form-item label="交付时间：" prop="finishDate">
                    <el-date-picker v-model="m.finishDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="交付时间"></el-date-picker>
                </el-form-item>

                <el-form-item label="项目地点：">
                    <el-input placeholder="项目地点" type="textarea" rows="2" style="width:50%" v-model="m.address">
                    </el-input>
                </el-form-item>

                <el-form-item label="项目清单：">
                    <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false" :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_rowFiles" :before-remove="remove_rowFiles" :file-list="m.rowFilesFile">
                        <el-button type="primary" size="mini" plain>点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">
                            上传项目清单
                        </div>
                    </el-upload>
                </el-form-item>

                <el-form-item label="合同编号：" prop="contactCode">
                    <el-input v-model="m.contactCode"></el-input>
                </el-form-item>

                <el-form-item label="合同附件：">
                    <el-upload class="upload-demo" :action="sa.cfg.api_url + '/file/upload'" :multiple="false" :data="{ fileType: 2, params: '' }" :limit="10" :on-success="success_contactFiles" :before-remove="remove_contactFiles" :file-list="m.contactFilesFile">
                        <el-button size="mini" type="primary" plain>点击上传</el-button>
                        <div slot="tip" class="el-upload__tip">
                            上传合同附件
                        </div>
                    </el-upload>
                </el-form-item>

                <el-form-item>
                    <el-button type="success" size="small" round @click="addItem()">添加录入合同</el-button>
                </el-form-item>

            </el-form>

            <el-collapse v-model="activeName" v-if="contracts.length" style=" margin-left: 100px;">
                <el-collapse-item v-for="(contract, index) in contracts" :key="contract.id" :title="'合同' + (contracts.length - index)" :name="index + 1">

                    <el-form size="mini" v-if="contract" :ref="'rulecontract'+(contracts.length - index)" :rules="rules1" :model="m" class="demo-ruleForm" label-width="120px">
                        <el-form-item label="合同名称：" prop="name">
                            <el-input v-model="contract.name"></el-input>
                        </el-form-item>
                        <el-form-item label="合同起始时间：" prop="startDate">
                            <el-date-picker v-model="contract.startDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="合同起始时间"></el-date-picker> -
                            <el-date-picker v-model="contract.endDate" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="合同结束时间"></el-date-picker>
                        </el-form-item>

                        <el-form-item label="甲方：" prop="firstName">
                            <el-input v-model="contract.firstName"></el-input>
                        </el-form-item>
                        <el-form-item label="乙方：" prop="secondName">
                            <el-input v-model="contract.secondName"></el-input>
                        </el-form-item>
                        <el-form-item label="合同内容">
                            <el-input type="textarea" rows="8" style="width:50%" placeholder="合同内容" v-model="contract.contact"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <span class="c-label">&emsp;</span>
                            <el-button type="success" icon="el-icon-plus" size="small" @click="submit(contract)">
                                {{contract.id?"修改":"保存"}}</el-button>
                            <el-button size="small" type="danger" icon="el-icon-delete" @click="remove(index)">删除
                            </el-button>
                        </el-form-item>
                    </el-form>
                </el-collapse-item>
            </el-collapse>
        </div>
    </div>
</template>

<script>
import inputEnum from "../../sa-resources/com-view/input-enum.vue";
export default {
    components: { inputEnum },
    props: ["params"],
    data() {
        return {
            m: {},
            title: "",
            isShow: false,
            rules1: {},
            contracts: [],
            activeName: 1,
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入项目名称",
                        trigger: "blur",
                    },
                ],
                state: [
                    {
                        type: "number",
                        message: "项目状态必须为数字类型",
                        trigger: "blur",
                    },
                    {
                        min: 0,
                        max: 4,
                        message: "状态不在有效区域",
                        trigger: "blur",
                    },
                ],
                code: [
                    {
                        required: true,
                        message: "请输入项目编码",
                        trigger: "blur",
                    },
                    {
                        min: 6,
                        max: 20,
                        message: "长度在 6 到 20 个字符",
                        trigger: "blur",
                    },
                ],
                finishDate: [],
                rowFiles: [],
                address: [],
                contactFiles: [],
            },
            fileList: [],
        };
    },
    methods: {
        open: function (data) {
            if (data.name) {
                this.title = "修改项目";
                data.rowFilesFile = JSON.parse(data.rowFiles);
                data.contactFilesFile = JSON.parse(data.contactFiles);
                this.m = data;
            } else {
                this.m = {
                    name: "",
                    state: 0,
                    code: "",
                    finishDate: "",
                    rowFiles: "",
                    address: "",
                    contactFiles: "",
                    rowFilesFile: [],
                    contactFilesFile: [],
                };
                this.title = "添加项目";
            }
            this.sa_admin.nativeTab.name = this.title;
        },

        success_rowFiles(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.rowFilesFile) {
                this.m.rowFilesFile = [];
            }
            this.m.rowFilesFile.push(response.data);
            console.log(fileList);
        },
        remove_rowFiles(file, fileList) {
            this.m.rowFilesFile = fileList;
        },
        success_contactFiles(response, file, fileList) {
            if (response.code != 200) {
                this.sa.error(response.message);
                return;
            }
            if (!this.m.contactFilesFile) {
                this.m.contactFilesFile = [];
            }
            this.m.contactFilesFile.push(response.data);
            console.log(fileList);
        },
        remove_contactFiles(file, fileList) {
            this.m.contactFilesFile = fileList;
        },

        //提交项目信息
        ok: function (formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.m.rowFiles = JSON.stringify(
                        this.m.rowFilesFile.map((item) => {
                            let a = {};
                            a.name = item.name;
                            a.url = item.url;
                            return a;
                        })
                    );
                    this.m.contactFiles = JSON.stringify(
                        this.m.contactFilesFile.map((item) => {
                            let a = {};
                            a.name = item.name;
                            a.url = item.url;
                            return a;
                        })
                    );
                    this.m.contractDTOS = this.contracts;

                    this.sa.post("/project/save", this.m).then((res) => {
                        this.sa.ok("处理项目基本信息成功");
                        this.sa_admin.replaceTo("project-list");
                    });
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },

        submit: function (contract) {
            console.log(667676, contract);
            if (contract.name.length < 2) {
                this.sa.error("请输入合同名称");
                return;
            }
            if (contract.firstName.length < 1) {
                this.sa.error("输入甲方");
                return;
            }
            if (contract.secondName.length < 1) {
                this.sa.error("输入乙方");
                return;
            }
            this.sa.ok("保存成功");
        },
        addItem() {
            if (JSON.stringify(this.contracts[0]) != "{}") {
                this.contracts.unshift({});
            } else {
                this.sa.error("当前没处理完全，不能重复添加");
            }
        },
        remove(index) {
            this.contracts.splice(index, 1);
        },
        history() {
            console.log("导航");
            this.sa_admin.changeTo("project-list");
        },
        f5() {
            let params = this.sa_admin.params;
            if (params.id) {
                this.m.id = params.id;
            }

            if (this.m.id) {
                this.sa
                    .get("/contract/getAllByProjectId/" + this.m.id)
                    .then((res) => {
                        if (res.data) {
                            this.contracts.push(...res.data);
                        }
                        console.log("xxxxxxxxxxx", this.contracts, res);
                    });
            }
        },
    },
    created() {
        let params = this.sa_admin.params;
        this.open(params);
        console.log(params);
        this.f5();
    },
};
</script>

<style  scoped>
.header {
    margin: 20px;
    display: flex;
    flex-direction: row;
}
.header-item {
    margin-left: 9px;
    margin-right: 10px;
    color: #c0c4cc;
    padding-top: 2px;
}

.title {
    font-size: 18px;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600;
    color: #2d2d2d;
    padding-left: 20px;
    border-left: 3px solid #4983f4;
    align-content: space-between;
    justify-content: space-between;
}

.el-collapse-item__header {
    font-size: 16px !important;
    font-family: PingFangSC-Semibold, PingFang SC;
    font-weight: 600 !important;
}

.dowItem {
    margin: 10px;
}
</style>